import { NgModule } from "@angular/core";
import {
  AutofocusDirective,
  ContainerClassNamePipe,
  DataFooterComponent,
  DirtyFormDirective,
  EnumToString,
  FdCardDefDirective,
  FdCardsComponent,
  FdLayoutBreadcrumbsComponent,
  FdLayoutComponent,
  FdLayoutContentComponent,
  FdLayoutDebugComponent,
  FdLayoutFixedFooterComponent,
  FdLayoutHeaderPrimaryComponent,
  FdLayoutHeaderSecondaryComponent,
  FdLayoutSidebarComponent,
  FdLayoutSidenavComponent,
  FdLayoutTitleComponent,
  FdLayoutToggleDirective,
  FdSelectSearchComponent,
  FdSortSelectComponent,
  FdViewLinksComponent,
  IfDisplayedDirective,
  IfSizeDirective,
  InputDirective,
  MatCellDirective,
  MatDialogCloseUnsupportedDirective,
  MatPaginatorDirective,
  MatSelectDirective,
  MatSpinnerPrefixSuffixDirective,
  SearchFormFieldComponent,
  SearchInputComponent,
  SplitButtonComponent,
  TableExporterComponent,
  TypeSafeMatCellDef,
} from "@fd/core";

const global = [
  // Components
  DataFooterComponent,
  FdCardsComponent,
  FdLayoutBreadcrumbsComponent,
  FdLayoutComponent,
  FdLayoutContentComponent,
  FdLayoutDebugComponent,
  FdLayoutFixedFooterComponent,
  FdLayoutHeaderPrimaryComponent,
  FdLayoutHeaderSecondaryComponent,
  FdLayoutSidebarComponent,
  FdLayoutSidenavComponent,
  FdLayoutTitleComponent,
  FdSortSelectComponent,
  FdViewLinksComponent,
  FdSelectSearchComponent,
  SearchFormFieldComponent,
  SearchInputComponent,
  SplitButtonComponent,
  TableExporterComponent,

  // Directives
  AutofocusDirective,
  DirtyFormDirective,
  FdCardDefDirective,
  FdLayoutToggleDirective,
  IfDisplayedDirective,
  IfSizeDirective,
  InputDirective,
  MatCellDirective,
  MatDialogCloseUnsupportedDirective,
  MatPaginatorDirective,
  MatSelectDirective,
  MatSpinnerPrefixSuffixDirective,
  TypeSafeMatCellDef,

  // Pipes
  ContainerClassNamePipe,
  EnumToString,
];

/**
 * All directives, components, and pipes that should be applied globally because they either:
 *   a) Should automatically augment built-in HTML elements always.
 *   b) Are used all the time so that it makes to just include them automatically.
 *
 * This module still needs to be imported in each individual module or standalone component for it to take effect.
 *
 * Any components that may be used, but don't fit the above description should not be included here.
 */
@NgModule({
  imports: global,
  exports: global,
})
export class SharedModule {}
