import { inject, Injectable } from "@angular/core";
import { AppEnvironment, ExternalRouteInfo, FdRouteInfo, FdRouteInfoData, Primitives } from "@fd/core";
import { environment } from "src/environments/environment";
import { UserRoles } from "./modules/shared/enums/user-roles.enum";
import { AuthService } from "./modules/shared/services/auth.service";

export class RouteInfo<PathParams extends Primitives[] = [], QueryParams extends {} = {}> extends FdRouteInfo<
  UserRoles,
  PathParams,
  QueryParams
> {}

@Injectable({ providedIn: "root" })
export class AppRoutes {
  constructor() {
    AppRoutes.auth = inject(AuthService);
  }
  static auth: AuthService;

  public static readonly root = new RouteInfo(null, "", { title: "", allowAnonymous: true });

  public static readonly login = new RouteInfo(AppRoutes.root, "login", {
    title: "Login",
    showInMenu: false,
    allowAnonymous: true,
  });
  public static readonly home = new RouteInfo(AppRoutes.root, "home", { title: "Home", showInMenu: false });
  public static readonly notAuthorized = new RouteInfo(AppRoutes.root, "not-authorized", {
    title: "Not Authorized",
    showInMenu: false,
    allowAnonymous: true,
  });

  public static timeTrackerCustom = new ExternalRouteInfo(
    AppRoutes.root,
    "Time Tracker",
    `${environment.mvcPortalBaseUrl}/TimeTracker/DayView`,
    { showInMenu: true, roleViews: [UserRoles.TimeTracker_DayView_View] }
  );
  public static peoplePlannerCustom = new RouteInfo(AppRoutes.root, "time-tracker/people-planner", {
    title: "People Planner",
    roleView: UserRoles.TimeTracker_PeoplePlanner_View,
  });

  public static projects = new RouteInfo(AppRoutes.root, "projects", {
    title: "Projects",
    showInMenu: true,
    roleView: UserRoles.Project_Projects_View,
  });

  public static dashboards = new ExternalRouteInfo(
    AppRoutes.root,
    "Dashboards",
    `${environment.mvcPortalBaseUrl}/Dashboards/Dashboard`,
    { showInMenu: true, roleViews: [UserRoles.Dashboards_Dashboard_View] }
  );

  public static timeTrackerRoot = new RouteInfo(AppRoutes.root, "time-tracker", {
    title: "Time Tracker",
    roleArea: UserRoles.TimeTracker,
    showInMenu: false,
  });

  public static timeTracker = {
    peoplePlanner: new RouteInfo(AppRoutes.timeTrackerRoot, "people-planner", {
      title: "People Planner",
      roleView: UserRoles.TimeTracker_PeoplePlanner_View,
    }),
  };

  public static billingRoot = new RouteInfo(AppRoutes.root, "billing", {
    title: "Billing",
    roleArea: UserRoles.Billing,
  });
  public static billing = {
    billingSummary: new ExternalRouteInfo(
      AppRoutes.billingRoot,
      "Billing Summary",
      `${environment.mvcPortalBaseUrl}/Billing/BillingSummary`,
      { showInMenu: true, roleViews: [UserRoles.Reports_Billable_View] }
    ),
  };

  public static salesRoot = new RouteInfo(AppRoutes.root, "sales", { title: "Sales", roleArea: UserRoles.Sales });
  public static sales = {
    estimates: new RouteInfo(AppRoutes.salesRoot, "estimates", {
      title: "Client Estimates",
      roleView: UserRoles.Sales_Estimate_View,
    }),
  };

  public static stewardshipRoot = new RouteInfo(AppRoutes.root, "stewardship", {
    title: "Stewardship",
  });
  public static stewardship = {
    kivaMap: new RouteInfo(AppRoutes.stewardshipRoot, "kiva-map", {
      title: "KIVA Map",
    }),
    kivaStats: new RouteInfo(AppRoutes.stewardshipRoot, "kiva-stats", {
      title: "KIVA Stats",
    }),
    manageKiva: new RouteInfo(AppRoutes.stewardshipRoot, "manage-kiva", {
      title: "Manage KIVA",
      roleView: UserRoles.Stewardship_Manage_View,
    }),
  };

  public static reportsRoot = new RouteInfo(AppRoutes.root, "reports", {
    title: "Reports",
    roleArea: UserRoles.Reports,
  });
  public static reports = {
    billablePercent: new RouteInfo(AppRoutes.reportsRoot, "billable-report", {
      title: "Billable %",
      roleView: UserRoles.Reports_Billable_View,
    }),

    timeActivityByProject: new RouteInfo(AppRoutes.reportsRoot, "timeactivity-by-project-report/:?id", {
      title: "Time Activity by Project",
      roleView: UserRoles.Reports_Billable_View,
    }),

    timeActivityByClient: new RouteInfo(AppRoutes.reportsRoot, "timeactivity-by-client-report/:?id", {
      title: "Time Activity by Client",
      roleView: UserRoles.Reports_TimeActivityByClient_View,
    }),
    timeActivityByPerson: new RouteInfo(AppRoutes.reportsRoot, "timeactivity-by-person-report/:?id", {
      title: "Time Activity by Person",
      roleView: UserRoles.Reports_TimeActivityByPerson_View,
    }),

    writeOffReport: new RouteInfo(AppRoutes.reportsRoot, "write-off-report", {
      title: "Write Off Report",
      roleView: UserRoles.Reports_WriteOffReport_View,
    }),
  };

  // #region Admin Module
  public static adminRoot = new RouteInfo(AppRoutes.root, "admin", {
    title: "Admin",
    roleArea: UserRoles.Admin,
  });
  public static admin = {
    categories: new ExternalRouteInfo(
      AppRoutes.adminRoot,
      "Categories",
      `${environment.mvcPortalBaseUrl}/Admin/Categories`,
      { showInMenu: true, roleViews: [UserRoles.Admin_Categories_View] }
    ),

    clients: new ExternalRouteInfo(AppRoutes.adminRoot, "Clients", `${environment.mvcPortalBaseUrl}/Admin/Clients`, {
      showInMenu: true,
      roleViews: [UserRoles.Admin_Clients_View],
    }),

    applications: new RouteInfo(AppRoutes.adminRoot, "applications", {
      title: "Applications",
      roleViews: [UserRoles.Admin_Applications_View],
    }),

    applicationStackComponents: new RouteInfo(AppRoutes.adminRoot, "application-stack-components", {
      title: "Application Stack Components",
      roleViews: [UserRoles.Admin_ApplicationStackComponents_View],
    }),

    qbCodes: new RouteInfo(AppRoutes.adminRoot, "qb-codes", {
      title: "QuickBook Codes",
      roleView: UserRoles.Admin_QBCodes_View,
    }),

    users: new ExternalRouteInfo(AppRoutes.adminRoot, "Team", `${environment.mvcPortalBaseUrl}/Admin/Team`, {
      showInMenu: true,
      roleViews: [UserRoles.Admin_Team_View],
    }),
    securityGroups: new RouteInfo(AppRoutes.adminRoot, "security-groups", {
      title: "Security Groups",
      roleView: UserRoles.Admin_SecurityGroups_View,
      additionalRoles: [UserRoles.Admin_SecurityGroups_Edit],
    }),
    holidayGroups: new RouteInfo(AppRoutes.adminRoot, "holiday-groups", {
      title: "Holidays",
      roleView: UserRoles.TimeTracker_HolidayGroups_View,
    }),
  };
  // #endregion

  public static helpfulLinksRoot = new RouteInfo(AppRoutes.root, "helpfulLinks", {
    title: "Helpful Links",
    allowAnonymous: true,
  });
  public static helpfulLinks = {
    reportABug: new ExternalRouteInfo(
      AppRoutes.helpfulLinksRoot,
      "Report a Bug",
      `https://forms.office.com/r/4bJzjp6378`,
      { showInMenu: true }
    ),
    submitASuggestion: new ExternalRouteInfo(
      AppRoutes.helpfulLinksRoot,
      "Submit a Suggestion",
      `https://forms.office.com/r/j97w4y2FrN`,
      { showInMenu: true }
    ),
    teamPlaybook: new ExternalRouteInfo(
      AppRoutes.helpfulLinksRoot,
      "Team Playbook",
      `https://freedomdevus.sharepoint.com/Team/Forms/AllItems.aspx?RootFolder=%2FTeam%2FPlaybook&FolderCTID=0x0120007819347027A5594FAA063FFDDF2A7899`,
      { showInMenu: true }
    ),
  };

  // #region Account Module
  public static accountRoot = new RouteInfo(null, "account", {
    title: "Account",
    showInMenu: false,
    allowAnonymous: true,
  });
  public static account = {
    registration: new RouteInfo(AppRoutes.accountRoot, "registration", {
      title: "Registration",
      allowAnonymous: true,
    }),
    externalLoginCallback: new RouteInfo(AppRoutes.accountRoot, "external-login-callback", {
      title: "Logging you in...",
      allowAnonymous: true,
    }),
    confirmEmail: new RouteInfo(AppRoutes.accountRoot, "confirm-email", {
      title: "Confirm Email",
      allowAnonymous: true,
    }),
    forgotPassword: new RouteInfo(AppRoutes.accountRoot, "forgot-password", {
      title: "Forgot Password",
      allowAnonymous: true,
    }),
    resetPassword: new RouteInfo(AppRoutes.accountRoot, "reset-password", {
      title: "Reset Password",
      allowAnonymous: true,
    }),
    profile: new RouteInfo(AppRoutes.accountRoot, "profile", { title: "Profile" }),
  };
  // #endregion

  // #region Framework Module
  public static frameworkRoot = new RouteInfo(AppRoutes.root, "framework", {
    title: "Framework",
    showInMenu: environment.configuration !== AppEnvironment.Production,
    roleArea: UserRoles.Framework,
  });
  public static frameworkSwaggerRoot = new ExternalRouteInfo(AppRoutes.frameworkRoot, "Swagger", "/swagger", {
    showInMenu: environment.configuration === AppEnvironment.Development,
    target: "swagger",
    roleViews: [UserRoles.Framework],
  });
  public static frameworkRinRoot = new ExternalRouteInfo(
    AppRoutes.frameworkRoot,
    "Request Inspector (Rin)",
    `${environment.localServerUrl}/rin`,
    {
      showInMenu: environment.configuration === AppEnvironment.Development,
      target: "rin",
      roleViews: [UserRoles.Framework],
    }
  );
  public static frameworkCoreRoot = new RouteInfo(AppRoutes.frameworkRoot, "core", {
    title: "Core",
    isNestedRoot: true,
    roleView: UserRoles.Framework,
  });
  public static frameworkControlsRoot = new RouteInfo(AppRoutes.frameworkRoot, "controls", {
    title: "Form Controls",
    isNestedRoot: true,
    roleView: UserRoles.Framework,
  });
  public static frameworkWidgetsRoot = new RouteInfo(AppRoutes.frameworkRoot, "widgets", {
    title: "Widgets",
    isNestedRoot: true,
    roleView: UserRoles.Framework,
  });
  public static frameworkLayoutsRoot = new RouteInfo(AppRoutes.frameworkRoot, "layouts", {
    title: "Layouts",
    isNestedRoot: true,
    roleView: UserRoles.Framework,
  });
  public static frameworkProofOfConceptsRoot = new RouteInfo(AppRoutes.frameworkRoot, "proof-of-concepts", {
    title: "Proof of Concepts",
    isNestedRoot: true,
    roleView: UserRoles.Framework_ProofOfConcepts,
  });
  public static framework = {
    // core
    about: new RouteInfo(AppRoutes.frameworkCoreRoot, "about", { title: "About" }),
    cache: new RouteInfo<["server" | "client"]>(AppRoutes.frameworkCoreRoot, "cache/:?view", {
      title: "Cache",
      defaultPathParams: ["server"],
    }),

    dateTime: new RouteInfo(AppRoutes.frameworkCoreRoot, "date-time", { title: "Date & Time" }),
    email: new RouteInfo(AppRoutes.frameworkCoreRoot, "email", { title: "Email" }),
    errorHandling: new RouteInfo(AppRoutes.frameworkCoreRoot, "error-handling", { title: "Error Handling" }),
    files: new RouteInfo<["ico" | "csv" | "xlsx" | "pdf"]>(AppRoutes.frameworkCoreRoot, "download-file/:?fileType", {
      title: "Files",
      defaultPathParams: ["ico"],
    }),
    form: new RouteInfo(AppRoutes.frameworkCoreRoot, "form", { title: "Form" }),
    globalLoadingIndicator: new RouteInfo(AppRoutes.frameworkCoreRoot, "global-loading-indicator", {
      title: "Global Loading Indicator",
    }),
    invalidModelState: new RouteInfo(AppRoutes.frameworkCoreRoot, "invalid-model-state", {
      title: "Invalid Model State ",
    }),
    pipes: new RouteInfo(AppRoutes.frameworkCoreRoot, "pipes", { title: "Pipes" }),
    prototyping: new RouteInfo(AppRoutes.frameworkCoreRoot, "prototyping", { title: "Prototyping" }),
    routing: new RouteInfo<[number, string, boolean?], { age?: number; grade?: string }>(
      AppRoutes.frameworkCoreRoot,
      "routing/:id/:name/:?flag",
      {
        title: "Routing",
        defaultPathParams: [0, "_"],
        defaultQueryParams: { age: 0 },
      }
    ),
    disabledExample: new RouteInfo(AppRoutes.frameworkCoreRoot, "disabled-example", {
      title: "Disabled Example",
      disabled: () => AppRoutes.auth.isLoggedIn,
    }),

    // form controls
    checkbox: new RouteInfo(AppRoutes.frameworkControlsRoot, "checkbox", { title: "Checkbox" }),
    datepicker: new RouteInfo(AppRoutes.frameworkControlsRoot, "datepicker", { title: "Date / Time Picker" }),
    number: new RouteInfo(AppRoutes.frameworkControlsRoot, "number", { title: "Number" }),
    select: new RouteInfo(AppRoutes.frameworkControlsRoot, "select", { title: "Select" }),
    text: new RouteInfo(AppRoutes.frameworkControlsRoot, "text", { title: "Text" }),
    toggle: new RouteInfo(AppRoutes.frameworkControlsRoot, "toggle", { title: "Toggle" }),
    uploadFile: new RouteInfo(AppRoutes.frameworkControlsRoot, "upload-file", { title: "Upload File" }),

    // widgets
    button: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "button", { title: "Button" }),
    chart: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "chart", { title: "Chart" }),
    confirmationPopover: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "confirmation-popover", {
      title: "Confirmation Popover",
    }),
    dataTables: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "data-tables", { title: "Data Tables" }),
    dialog: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "dialog", { title: "Dialog" }),
    menu: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "menu", { title: "Menu" }),
    snackBar: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "snack-bar", { title: "Snack Bar" }),
    tooltip: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "tooltip", { title: "Tooltip" }),

    // layouts
    layoutCards: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "cards/:?view", {
      title: "Cards",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutTableEditable: new RouteInfo(AppRoutes.frameworkLayoutsRoot, "table-editable", {
      title: "Editable Table",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutReport: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "report/:?view", {
      title: "Report",
      defaultQueryParams: { type: "sidebar", showViewLinks: true, showActions: false },
    }),
    layoutTable: new RouteInfo<[string]>(AppRoutes.frameworkLayoutsRoot, "table", {
      title: "Table",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutUtilities: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "layout-utilities/:?view", {
      title: "Utilities",
    }),

    // proof of concepts
    barcode: new RouteInfo(AppRoutes.frameworkProofOfConceptsRoot, "barcode", {
      title: "Barcode",
    }),
    scanner: new RouteInfo(AppRoutes.frameworkProofOfConceptsRoot, "scanner", {
      title: "Scanner",
    }),
    platforms: new RouteInfo(AppRoutes.frameworkProofOfConceptsRoot, "platforms", {
      title: "Platforms",
    }),
  };
  // #endregion
}

export type RouteInfoData<PathParams extends Primitives[] = [], QueryParams extends {} = {}> = FdRouteInfoData<
  UserRoles,
  PathParams,
  QueryParams
>;

export type PathParamsForRoute<T> = T extends RouteInfo<infer PathParams> ? PathParams : never;

export type QueryParamsForRoute<T> = T extends RouteInfo<never, infer QueryParams> ? QueryParams : never;
