<mat-chip-listbox>
  <mat-chip
    (click)="$event.preventDefault()"
    [matBadge]="serverEnvironment !== undefined && clientEnvironment !== serverEnvironment ? '!' : ''"
    matBadgeColor="warn"
    [matMenuTriggerFor]="environmentUrls"
    [matTooltip]="
      serverEnvironment !== undefined && clientEnvironment !== serverEnvironment
        ? 'Client environment is ' + AppEnvironment[clientEnvironment]
        : ''
    ">
    <span class="d-none d-md-block">{{ AppEnvironment[appEnvironment] }}</span>
    <span class="d-md-none">{{ AppEnvironment[appEnvironment][0] }}</span>
  </mat-chip>
</mat-chip-listbox>

<mat-menu #environmentUrls>
  @for (env of environments; track env.configuration) {
    <a
      mat-menu-item
      [target]="AppEnvironment[env.configuration]"
      [href]="env.configuration === AppEnvironment.Testing ? env.testingUrl : env.productionUrl"
      *ngIf="clientEnvironment !== env.configuration">
      {{ AppEnvironment[env.configuration] }} Site
      <mat-icon>open_in_new</mat-icon>
    </a>
  }
</mat-menu>
