import { NgModule } from "@angular/core";
import { PreloadAllModules, Route, RouterModule, UrlSerializer } from "@angular/router";
import { AppEnvironment, AuthGuard, LowercaseUrlSerializer } from "@fd/core";
import { AppRoutes } from "src/app/routes";
import { environment } from "src/environments/environment";
import { HomeComponent } from "./components/home/home.component";
import { LoginComponent } from "./components/login/login.component";
import { NotAuthorizedComponent } from "./components/not-authorized/not-authorized.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { RedirectComponent } from "./components/redirect/redirect.component";
import { AuthService } from "./modules/shared/services/auth.service";

const routes: Route[] = [
  {
    path: "",
    component: RedirectComponent,
    canActivate: [AuthGuard],
    pathMatch: "full",
  },

  // pages
  ...AppRoutes.home.toRoutes(HomeComponent),
  ...AppRoutes.login.toRoutes(LoginComponent),
  ...AppRoutes.notAuthorized.toRoutes(NotAuthorizedComponent),

  // lazy-loaded modules
  AppRoutes.accountRoot.toLazyRoute(() => import("./modules/account/account.module").then(m => m.AccountModule)),
  AppRoutes.adminRoot.toLazyRoute(() => import("./modules/admin/admin.module").then(m => m.AdminModule)),
  AppRoutes.reportsRoot.toLazyRoute(() => import("./modules/reports/reports.module").then(m => m.ReportsModule)),
  AppRoutes.salesRoot.toLazyRoute(() => import("./modules/sales/sales.module").then(m => m.SalesModule)),
  AppRoutes.stewardshipRoot.toLazyRoute(() =>
    import("./modules/stewardship/stewardship.module").then(m => m.StewardshipModule)
  ),

  // Top level lazy loaded modules
  { path: "", loadChildren: () => import("./modules/project/project.module").then(m => m.ProjectModule) },

  AppRoutes.timeTrackerRoot.toLazyRoute(() =>
    import("./modules/time-tracker/time-tracker.module").then(m => m.TimeTrackerModule)
  ),

  {
    path: "**",
    component: NotFoundComponent,
    data: { title: "Page Not Found" },
  },
];

if (environment.configuration !== AppEnvironment.Production) {
  // only load the framework module if the environment isn't production.
  routes.splice(
    1,
    0,
    AppRoutes.frameworkRoot.toLazyRoute(() =>
      import("./modules/framework/framework.module").then(m => m.FrameworkModule)
    )
  );
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // PreloadAllModules loads all the lazy loaded modules asynchronously on page load
      preloadingStrategy: PreloadAllModules,
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
  providers: [AuthService, AuthGuard, { provide: UrlSerializer, useClass: LowercaseUrlSerializer }],
})
export class AppRoutingModule {}
