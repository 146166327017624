import { EventEmitter, Injectable, inject } from "@angular/core";
import { LocalStoreManager } from "@fd/core";
import { environment } from "src/environments/environment";
import { DbKeys } from "../enums/db-keys.enum";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private readonly environment = environment;
  private readonly localStoreManager = inject(LocalStoreManager);

  private theme: Theme = "light";

  readonly onThemeChange = new EventEmitter<Theme>();

  get activeTheme() {
    return this.theme;
  }

  get themesEnabled() {
    return !!this.environment?.theme.supportsDarkMode;
  }

  initialize() {
    this.theme = (this.localStoreManager.getData(DbKeys.Theme) as Theme) || "light";
    this.applyThemeClass();
  }

  toggleTheme() {
    this.theme = this.theme === "dark" ? "light" : "dark";
    this.localStoreManager.saveSessionData(this.theme, DbKeys.Theme);
    this.applyThemeClass();
    this.onThemeChange.emit(this.theme);
  }

  private applyThemeClass() {
    document.body.classList.toggle("theme-light", this.theme === "light");
    document.body.classList.toggle("theme-dark", this.theme === "dark");
  }
}

export type Theme = "light" | "dark";