import { MatFormFieldDefaultOptions } from "@angular/material/form-field";
import { MatPaginatorDefaultOptions } from "@angular/material/paginator";
import { Capacitor } from "@capacitor/core";
import { AppEnvironment, IEnvironment, Utilities } from "@fd/core";
import { PasswordValidation } from "@rxweb/reactive-form-validators/models";

export class Environment extends IEnvironment {
  override configuration: AppEnvironment;
  mvcPortalBaseUrl: string;
  jiraBaseUrl: string;
  override passwordRequirements: PasswordValidation;
  override passwordRequirementMessages: string[];
  override onlineStatusNotifications: { enabled: boolean; connectionRestored: string; connectionLost: string };
  override matPaginatorDefaultOptions: MatPaginatorDefaultOptions;
  override matFormFieldDefaultOptions: MatFormFieldDefaultOptions;
  override testingUrl: string;
  override productionUrl: string;
  override localServerUrl: string;
  override theme?: { supportsDarkMode: boolean };
  isFdAngularSite: boolean;

  override get apiUrl() {
    return this.configuration === AppEnvironment.Testing
      ? this.testingUrl
      : this.configuration === AppEnvironment.Development
        ?  Utilities.baseUrl() //if the front and back ends are coupled
        : this.productionUrl;
  }

  constructor(init?: Partial<Environment>) {
    super();
    Object.assign(this, init);
  }
}

let localServerUrl = require("../../../Properties/launchSettings.json").profiles.Development.applicationUrl;

if (Capacitor.getPlatform() === "android") {
  // when running on an android emulator, the local server must be accessed via 10.0.2.2
  // however, it is having issue with the SSL connection, so we are using ngrok now now.
  /* todo: figure out how to get the local HTTPS connection to work
    localServerUrl = localServerUrlHttp.replace("localhost", "10.0.2.2").replace("127.0.0.1", "10.0.2.2");
  */
  localServerUrl = "https://d7e1-24-204-171-60.ngrok-free.app";
}

export const defaultEnvironment = new Environment({
  isFdAngularSite: document.title === "FD Angular",
  configuration: AppEnvironment.Development,
  testingUrl: "https://portal2-dev.freedomdev.com",
  productionUrl: "https://portal2.freedomdev.com",
  mvcPortalBaseUrl: "https://portal-dev.freedomdev.com",
  jiraBaseUrl: "https://freedomdev-sandbox-867.atlassian.net",
  localServerUrl,
  passwordRequirements: {
    minLength: 6,
    digit: true,
    upperCase: true,
    lowerCase: true,
  },
  passwordRequirementMessages: [
    "Must be at least 6 characters in length.",
    "Must contain an uppercase letter.",
    "Must contain a lowercase letter.",
    "Must contain a number.",
  ],
  onlineStatusNotifications: {
    enabled: true, // When true, notifications will be shown when network connectivity is lost or restored
    connectionRestored: "You are now back online!",
    connectionLost: "You are offline.",
  },
  matPaginatorDefaultOptions: {
    formFieldAppearance: "fill",
    hidePageSize: false,
    pageSize: 25,
    pageSizeOptions: [5, 25, 100],
    showFirstLastButtons: true,
  },
  matFormFieldDefaultOptions: {
    appearance: "fill",
  },
  theme: {
    // set to true to enable dark mode. In styles/_variables.scss $dark-mode must be true as well
    supportsDarkMode: true,
  },
});
