import { Component, ElementRef, Input, OnInit, ViewChild, inject, numberAttribute } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { ExternalRouteInfo, FdRouterService, IRouteInfo } from "@fd/core";
import { BaseComponent } from "../../base.component";

import { RouteInfo } from "src/app/routes";

@Component({
  selector: "app-nested-nav-item",
  templateUrl: "./nested-nav-item.component.html",
  styleUrl: "./nested-nav-item.component.scss",
})
export class NestedNavItemComponent extends BaseComponent implements OnInit {
  @Input() link: IRouteInfo;
  @Input({ transform: numberAttribute }) level: number;
  @Input() drawer: MatSidenav;

  @ViewChild("panel", { read: ElementRef }) panel: ElementRef<HTMLElement>;

  private readonly fdRouter = inject(FdRouterService);
  protected readonly router = inject(Router);

  isActive: boolean;
  hasAccess: boolean;

  ngOnInit() {
    this.isActive = this.linkIsActive();

    this.hasAccess = this.userHasAccess();
    this.subscriptions.push(
      this.fdRouter.navigationEnd$.subscribe(() => (this.isActive = this.linkIsActive())),
      this.authService.getLoginStatusEvent().subscribe(() => (this.hasAccess = this.userHasAccess()))
    );
  }

  private userHasAccess() {
    if (this.link instanceof RouteInfo) {
      return (
        this.link.data.allowAnonymous ||
        (this.authService.currentUser?.hasAnyViewRole(...this.link.data.roleAreas) &&
          this.authService.currentUser?.isInRole(...this.link.data.roleViews))
      );
    }
    if (this.link instanceof ExternalRouteInfo) {
      return (
        this.link.allowAnonymous ||
        (this.authService.currentUser?.hasAnyViewRole(...this.link.data.roleAreas) &&
          this.authService.currentUser?.isInRole(...this.link.data.roleViews))
      );
    }
    return true;
  }

  private linkIsActive(link?: IRouteInfo): boolean {
    link ??= this.link;

    const isActive = (link: IRouteInfo) => {
      return (
        link.routerLink &&
        this.router.isActive(link.routerLink, {
          paths: "subset",
          queryParams: "subset",
          fragment: "ignored",
          matrixParams: "ignored",
        })
      );
    };

    return (
      isActive(link) ||
      link.children?.some(childLink => {
        if (childLink.children) {
          return childLink.children.length && this.linkIsActive(childLink);
        }

        return isActive(childLink);
      })
    );
  }
}
