import { Component, Input, booleanAttribute, numberAttribute } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { IRouteInfo } from "@fd/core";
import { RouteInfo } from "src/app/routes";

@Component({
  selector: "app-nav-item",
  templateUrl: "./nav-item.component.html",
  styleUrl: "./nav-item.component.scss",
})
export class NavItemComponent {
  @Input() link: IRouteInfo;
  @Input({ transform: numberAttribute }) level: number;
  @Input() drawer: MatSidenav;
  @Input({ transform: booleanAttribute }) isHidden: boolean;

  routerLink(link: IRouteInfo) {
    if (link instanceof RouteInfo && !link.disabled()) {
      return link.buildRouterLink();
    }

    return null;
  }

  queryParams(link: IRouteInfo) {
    if (link instanceof RouteInfo) {
      return link.buildQueryParams(link.data.defaultQueryParams);
    }

    return null;
  }
}
