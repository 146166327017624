import { AppEnvironment } from "@fd/core";
import { Environment, defaultEnvironment } from "./environment.definition";

export const environment: Environment = new Environment(
  Object.assign({}, defaultEnvironment, {
    configuration: defaultEnvironment.isFdAngularSite ? AppEnvironment.Beta : AppEnvironment.Production,
    mvcPortalBaseUrl: "https://portal.freedomdev.com",
    jiraBaseUrl: "https://freedomdev.atlassian.net",
  })
);
