import { CurrentUserInterface } from "@fd/core";
import { UserRoles } from "../../shared/enums/user-roles.enum";

/** The cached data contract for the current user */
export interface UserDtoInterface extends CurrentUserInterface {
  id: string;
  impersonatingIdentityId?: string;
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  emailConfirmed: boolean;
  roles: string[];
  isInRole: (...roles: (UserRoles | string)[]) => boolean;
  hasAnyViewRole: (...rolePrefixes: (UserRoles | string)[]) => boolean;
}

export class UserDtoModel implements UserDtoInterface {
  id: string;
  impersonatingIdentityId?: string;
  userId: number;
  email: string;
  userName: string;
  firstName: string;
  lastName: string;
  emailConfirmed: boolean;
  roles: string[];

  /**
   * Returns true if the logged in user has any "view" role with the provided prefix or if `rolePrefix` is falsey.
   * @param role
   */
  hasAnyViewRole(...rolePrefixes: (UserRoles | string)[]) {
    if (!rolePrefixes || rolePrefixes[0] == undefined) {
      return true;
    }

    return this.roles && this.roles.some(r => rolePrefixes.some(rolePrefix => r.startsWith(rolePrefix)));
  }

  /**
   * Returns true if the logged in user has any of the provided role or if `role` is falsey.
   * @param role
   */
  isInRole(...roles: (UserRoles | string)[]) {
    if (!roles || roles[0] == undefined) {
      return true;
    }
    return this.roles && this.roles.some(role => roles.indexOf(role) !== -1);
  }
}
