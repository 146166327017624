import { Component, Input } from "@angular/core";
import { AppEnvironment } from "@fd/core";
import { environment as productionEnvironment } from "src/environments/environment.production";
import { environment as testingEnvironment } from "src/environments/environment.testing";

@Component({
  selector: "app-environment-chip",
  templateUrl: "./environment-chip.component.html",
  styleUrl: "./environment-chip.component.scss",
})
export class EnvironmentChipComponent {
  @Input() clientEnvironment: AppEnvironment;
  @Input() serverEnvironment: AppEnvironment;

  readonly AppEnvironment = AppEnvironment;
  readonly environments = [testingEnvironment, productionEnvironment];

  get appEnvironment(): AppEnvironment {
    return this.serverEnvironment === undefined ? this.clientEnvironment : this.serverEnvironment;
  }
}
