<!-- We are not going to allow more than three levels of nested items in the main sidenav -->
<div *ngIf="level < 3 && link.showInMenu" [hidden]="isHidden">
  <a
    *ngIf="link.externalLink"
    mat-list-item
    [href]="link.externalLink"
    [target]="$any(link).data.target"
    [disabled]="link.disabled()">
    <span style="--level: {{ level }}" class="level mat-list-text d-flex justify-content-between">
      {{ link.label }}
      <mat-icon>open_in_new</mat-icon>
    </span>
  </a>

  <a
    *ngIf="link.routerLink"
    mat-list-item
    [routerLink]="routerLink(link)"
    [queryParams]="queryParams(link)"
    routerLinkActive="active"
    (click)="drawer?.toggle()"
    [disabled]="link.disabled()">
    <span style="--level: {{ level }}" class="level mat-list-text">
      {{ link.label }}
    </span>
  </a>
</div>
